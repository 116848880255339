import React from 'react';
import TextImageDeck from './TextImage/TextImageDeck';
import BulletsDeck from './BulletList/BulletsDeck';
import CardListDeck from './CardList/CardListDeck';
import HeroDeck from './Hero/HeroDeck';
import TitleDeck from './Title/TitleDeck';
import ContactForm from 'components/decks/Contact/ContactForm';
import Animation from '../system/Animation';

const Deck = ({ data }) => {

  const { type, animation, animationDirection } = data;

  const componentMap = {
    'title': TitleDeck,
    'textMedia': TextImageDeck,
    'hero': HeroDeck,
    'bullets': BulletsDeck,
    'cards': CardListDeck,
    'webform': ContactForm
  }
  
  const DeckComponent = ({ type, children, ...props }) => {
    return React.createElement(
      componentMap[type],
      props,
      children
    );
  }

  // Put animation direction in object and destructure it later
  const direction = animationDirection && { [animationDirection]: true };

  if (data.animation) {
    return (
      <Animation type={animation} {...direction}>
        <DeckComponent type={type} {...data} />
      </Animation>
    );
  }
  return <DeckComponent type={type} {...data} />
};

export default Deck;
