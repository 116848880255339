import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../base/Icon';
import Animation from '../../system/Animation';

const Bulletpoint = ({ icon, title, text, animation, animationDirection }) => {
  const bullet = (
    <div className="bullet">
      <div className="title-container">
        {icon && <Icon icon={icon} />}
        {title && <h3 className="title">{title}</h3>}
      </div>
      {text && <p className="text">{text}</p>}
    </div>
  );

  // Put animation direction in object and destructure it later
  const direction = animationDirection && { [animationDirection]: true };

  return animation ? (
    <Animation type={animation} {...direction}>
      {bullet}
    </Animation>
  ) : (
    bullet
  );
};

Bulletpoint.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default Bulletpoint;
