import React from 'react';
import { Route } from 'react-router-dom';
import './Main.scss';
import Ajax from '../../system/Ajax';
import urlPrefix from "../../../util/urlPrefix";

const Main = ({ storeLanguagePath }) => {

  return (
    <main className="main">
      <Route 
        exact path={[urlPrefix(), urlPrefix() + ':id']}
        render={
          props => <Ajax {...props} 
          storeLanguagePath={storeLanguagePath}
        />}
      />
    </main>
  );
};

export default Main;
