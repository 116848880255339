import React from 'react';
import Lottie from 'react-lottie';
import './HeroDeck.scss';
import Image from '../../base/Image';
import Cta from 'components/base/Cta';

const HeroDeck = ({ media, title, titleTag, subhead, backgroundImage, ctaParagraph }) => {
  // Small hack to create dynamic title element (h1, h2 etc.)
  const TitleTag = titleTag ? `${titleTag}` : 'h2';

  const isJson = media && media.type === 'json';
  const lottieOptions = isJson && {
    loop: true,
    autoplay: true,
    animationData: JSON.parse(media.src),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    }
  };

  const inner = 
    <div className="inner">
      {media && (isJson ? <Lottie options={lottieOptions} isClickToPauseDisabled={true} /> : <Image image={media} />)}
      <div className="content-wrapper">
        <TitleTag className="title">{title}</TitleTag> 
        {subhead && <div className="subhead">{subhead}</div>}
        {ctaParagraph && 
          <div className="cta-container">
          {ctaParagraph.map((cta, index) => <Cta key={index} {...cta} />)}
          </div>}
      </div>
    </div>
  ;

  if (backgroundImage.src) {
    return (
      <div className="hero deck background-image" style={{ backgroundImage: `url(${backgroundImage.src})` }}>
        {inner}
      </div>
    )
  }

  return (
    <div className="hero deck">
      {inner}
    </div>
  );
};

export default HeroDeck;
