import React, { useState } from 'react';
import axios from 'axios';
import currentLanguage from 'util/currentLanguage';
import urlPrefix from 'util/urlPrefix';

const Submit = ({ fields, field, backendError, setBackenderror, resetFormFields, webform, formData, setFormdata, TIMEOUT, formRef }) => {
  const [isSubmitted, setIssubmitted] = useState(false);

  const onSubmit = async e => {
    const isValid = formRef.current.reportValidity();

    if (isValid) {
      e.preventDefault();

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      const body = {
        webform_id: webform,
        ...formData
      };

      try {
        const result = await axios.post(
          urlPrefix() + 'webform_rest/submit',
          body,
          config
        );

        if (result.data.error) {
          setBackenderror(result.data.error);

          setTimeout(() => {
            setBackenderror({});
          }, TIMEOUT);
        } else {
          resetFormFields(fields, setFormdata);

          setIssubmitted(true);
          setTimeout(() => {
            setIssubmitted(false);
          }, TIMEOUT);
        }
      } catch (error) {
        console.log('Form submission error: ', error);
      }
    }
  };

  const message =
    currentLanguage() === 'da'
      ? 'Din besked er blevet sendt!<br>Vi svarer tilbage hurtigst muligt.'
      : 'Your message has been sent!<br> We will reply to you quickly.';

  return (
    <>
      {isSubmitted && !Object.keys(backendError).length > 0 && (
        <p dangerouslySetInnerHTML={{ __html: message }} className="send-confirmation" />
      )}
      <button type="button" onClick={onSubmit} className="button submit">
        {field['#submit__label']}
      </button>
    </>
  );
};

export default Submit;