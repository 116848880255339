import React from 'react';
import Cta from '../../base/Cta';
import Image from '../../base/Image';
import TextDeck from '../TextImage/TextDeck';
import Animation from '../../system/Animation';
import './Card.scss';

const Card = props => {
  const { ctaParagraph, media, text, title, animation, animationDirection, number } = props;

  const delay = window.innerWidth >= 888 ? number * 500 : 0;
  
  const card = 
    <div className="card">
      {media && (
        <div className="image-container">
          <Image image={media} />
        </div>
      )}
      {title && <h3 className="title">{title}</h3>}
      {text && <TextDeck text={text} />}
      {ctaParagraph && ctaParagraph.map((cta, index) => <Cta key={index} {...cta} />)}
    </div>;

  // Put animation direction in object and destructure it later
  const direction = animationDirection && { [animationDirection]: true };

  return animation ? (
    <Animation 
      type={animation} 
      {...direction}
      delay={delay}
    >
      {card}
    </Animation>
  ) : card;

};

export default Card;
