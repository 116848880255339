import React from 'react';
import { Link } from 'react-router-dom';
import './LanguageSwitcher.scss';

const LanguageSwitcher = ({ languagePaths, forceUpdate }) => {

  return (

    <span className="language-selector">
      {Object.keys(languagePaths).map(langCode => {
        const url = languagePaths[langCode];
        return (
          <Link
            to={url} key={langCode}
            className={'language-link ' + langCode}
            onClick={forceUpdate}
          >
            {langCode.toUpperCase()}
          </Link>
        )
      })}
    </span>

  );
};

export default LanguageSwitcher;
