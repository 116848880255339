import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './util/ScrollToTop';
import './App.scss';
import './components/base/Button.scss';

import Header from './components/layout/header/Header';
import Main from './components/layout/main/Main';
import Footer from './components/layout/footer/Footer';

function App() {

  const [languagePaths, setLanguagePaths] = useState({});

  // eslint-disable-next-line
  const [update, setUpdate] = useState();

  const forceUpdate = () => {
    setUpdate(Math.random());
  }

  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <Header 
            languagePaths={languagePaths}
            forceUpdate={forceUpdate}
          />
          <Main
            storeLanguagePath={setLanguagePaths}
          />
        </ScrollToTop>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
