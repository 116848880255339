import React from 'react';
import Cta from 'components/base/Cta';
import currentLanguage from 'util/currentLanguage';

const ArticleItem = ({ article }) => {
  const cta = {
    title: currentLanguage() === 'da' ? 'Læs mere' : 'Read more', 
    uri: article.path[currentLanguage()]
  }

  return (
    <div>
      <h2 className="title">{article.title}</h2>
      <p
        dangerouslySetInnerHTML={{ __html: article.teaser }}
        className="text"
      />
      <div className="cta-container">
        <Cta cta={cta} />
      </div>
    </div>
  );
};

export default ArticleItem;