import React from 'react';
import { Zoom, Fade, Slide } from 'react-reveal';
import Pulse from 'react-reveal/Pulse';

const Animation = ({ type, children, ...props }) => {

  const animationMap = {
    'zoom': Zoom,
    'fade': Fade,
    'slide': Slide,
    'pulse': Pulse,
  }
  return React.createElement(
    animationMap[type],
    props,
    children
  );
}

export default Animation;
