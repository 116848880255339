import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import './Navigation.scss';
import urlPrefix from "../../../util/urlPrefix";

 const Navigation = () => {

  const [data, setData] = useState([]);

  const backendNav = urlPrefix() + 'api/v1/navigation/main?_format=json';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios(backendNav);
        setData(result.data);
      } catch (error) {
        setData([]);
      }
    };
    fetchData();
  }, [backendNav]);

  return (
    <nav className="navigation">
      <ul>
        {data.map((link) => (
          <li key={link.weight}>
            <NavLink to={link.relative}>{link.title}</NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
