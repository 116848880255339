import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../base/Image';
import Cta from '../../base/Cta';
import './TextImageDeck.scss';
import './TextImageTwoColumn.scss';
import TextDeck from './TextDeck';

const TextImageDeck = props => {
  
  const {
    layout,
    title,
    titleTag,
    media,
    textRich,
    ctaParagraph,
    bigBullets,
    textSize,
    backgroundColor
  } = props;
  
  const HeaderTag = titleTag ? `${titleTag}` : 'h2';

  return (
    <div className={"text-image deck" + (backgroundColor ? " " + backgroundColor : "")}>
      <div
        className={
          "inner" +
          (layout ? " " + layout : "") +
          (bigBullets ? " bigbullets" : "") +
          (textSize ? " " + textSize : "")
        }
      >
        {media && <Image image={media} />}
        {title && <HeaderTag className="title">{title}</HeaderTag>}
        {textRich &&
          textRich.map((text, index) => <TextDeck key={index} text={text} />)}
        {ctaParagraph && 
          <div className="cta-container">
            {ctaParagraph.map((cta, index) => <Cta key={index} {...cta} />)}
          </div>}
      </div>
    </div>
  );
};

TextImageDeck.propTypes = {
  media: PropTypes.object,
  textRich: PropTypes.array.isRequired
};

export default TextImageDeck;
