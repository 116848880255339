import React from 'react';
import './BulletsDeck.scss';
import Bulletpoint from './Bulletpoint';

const BulletsDeck = ({ title, bulletpoint, backgroundColor }) => {
  
  return (
    <div className={`
     bullets-deck
     ${backgroundColor && backgroundColor}
    `}>
      <h2 className="title">{title}</h2>
      <div className="bullets">
        {bulletpoint.map((bulletItem, index) => (
          <Bulletpoint key={index} {...bulletItem} />
        ))}
      </div>
    </div>
  );
};

export default BulletsDeck;
