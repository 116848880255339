import React from 'react';
import PropTypes from 'prop-types';
import './TextDeck.scss';
import './BigBullets.scss';

const TextDeck = ({ text }) => {

  return (
    <div
      className="text"
      dangerouslySetInnerHTML={{
        __html: text
      }}
    />
  )
}

export default TextDeck;

TextDeck.propTypes = {
  text: PropTypes.string.isRequired
};