import React from 'react';
import './NotFound.scss';
import currentLanguage from 'util/currentLanguage';

const NotFound = () => {
  const text = currentLanguage() === 'da' ? 'Side ikke fundet' : 'Page not found';

  return (
    <div className="notfound">
      <div className="inner">
        <h1>404</h1>
        <h2>{text}</h2>
      </div>
    </div>
  );
};

export default NotFound;
