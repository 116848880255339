import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Deck from '../decks/Deck';
import Articles from 'components/layout/main/Articles';
import NotFound from '../system/NotFound';
import Helmet from 'react-helmet';
import urlPrefix from '../../util/urlPrefix';
import currentLanguage from '../../util/currentLanguage';

const Ajax = props => {
  const { storeLanguagePath } = props;
  const [data, setData] = useState([]);

  const backendUrl = urlPrefix() + 'api/v1/pagebuilder';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios(backendUrl);
        setData(result.data);
      } catch (error) {
        setData([]);
      }
    };
    fetchData();
  }, [backendUrl]);
  
  // We are fething data, if data is empty. We believe this is fast, so don't
  // display spinner or anything.
  if (data.length === 0) {
    return <></>;
  }

  const url = props.match.url;

  if (url === '/artikler' || url === '/en/articles') {
    return (
      <>
        <Helmet>
          <html lang={currentLanguage()}></html>
        </Helmet>
        <Articles data={data} storeLanguagePath={storeLanguagePath} />
      </>
    );
  }

  let page = data.pagebuilders.find(page => page.path[currentLanguage()] === url);

  if (!page && url.slice(-1) === '/') {
    page = data.pagebuilders.find(page => page.path[currentLanguage()] === url.slice(0, -1));
  }

  if (page === undefined) {
    return <NotFound />;
  }

  // Make language paths available to language switcher
  storeLanguagePath(page.path);

  const metatags = page.metaTags && (
    <Helmet>
      <title>{page.metaTags.title && page.metaTags.title}</title>
      <meta
        name="description"
        content={page.metaTags.description && page.metaTags.description}
      />
    </Helmet>
  );

  return (
    <>
      <Helmet>
        <html lang={currentLanguage()}></html>
      </Helmet>
      {metatags}
      <div className={`page ${page.layout} ${page.isFrontpage && "front-page"}`}>
        {page.decks.map((deck, index) => {
          return <Deck key={index} data={deck} />;
        })}
      </div>
    </>
  );
};

export default Ajax;
