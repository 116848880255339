import React from 'react';
import Card from './Card';
import './CardListDeck.scss';

const CardListDeck = ({ title, subhead, card }) => {

  return (
    <div className="cardlist deck">
      {title && <h2 className="title">{title}</h2>}
      {subhead && <p className="subhead">{subhead}</p>}
      <div className="cards">
        {card.map((data, index) => (
          <Card key={index} number={index} {...data} />
        ))}
      </div>
    </div>
  );
};

export default CardListDeck;
