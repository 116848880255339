import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import './Header.scss';
import LanguageSwitcher from './LanguageSwitcher';
import urlPrefix from "../../../util/urlPrefix";

const Header = ({ languagePaths, forceUpdate }) => {

  const [navOpen, setNavOpen] = useState(false);

  // Temporary solution to fix URL bug
  let frontPageLink = urlPrefix();
  if (frontPageLink === '/en/') {
    frontPageLink = '/en';
  }

  return (
    <header className="header">
      <Link
        to={frontPageLink}
        className="home-link"
        aria-label="Header Home Link"
      >
        <svg
          className="logo"
          viewBox="0 0 193.5 188"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="9.06">
            <path d="m96.7 23-.2 35.4-30.6 17.5-30.5-17.9.1-35.3 30.7-17.5z" />
            <path d="m157.9 23.4-.2 35.3-30.6 17.5-30.5-17.8.2-35.3 30.6-17.5z" />
            <path d="m127.1 76.2-.2 35.3-30.7 17.5-30.5-17.8.2-35.3 30.7-17.5z" />
            <path d="m188.9 76.9-.1 35.3-30.7 17.5-30.5-17.8.2-35.3 30.6-17.5z" />
            <path d="m158.1 129.7-.2 35.4-30.7 17.5-30.5-17.9.2-35.3 30.7-17.5z" />
            <path d="m96.9 130-.2 35.3-30.7 17.5-30.4-17.8.1-35.3 30.7-17.5z" />
            <path d="m65.9 76.4-.2 35.4-30.7 17.5-30.5-17.9.2-35.3 30.7-17.5z" />
            <path d="m4.7 76.1 30.5 17.3" />
            <path d="m65.9 76.4-30.7 17" />
            <path d="m35.7 129.7-.5-36.3" />
            <path d="m35.5 22.7 30.5 17.3" />
            <path d="m96.7 23-30.7 17" />
            <path d="m66.6 76.3-.6-36.3" />
            <path d="m96.8 23.1 30.4 17.2" />
            <path d="m157.9 23.4-30.7 16.9" />
            <path d="m127.8 76.6-.6-36.3" />
            <path d="m65.9 75.9 30.5 17.2" />
            <path d="m127.1 76.2-30.7 16.9" />
            <path d="m96.9 129.4-.5-36.3" />
            <path d="m127.8 76.6 30.4 17.2" />
            <path d="m188.9 76.9-30.7 16.9" />
            <path d="m158.8 130.2-.6-36.4" />
            <path d="m96.5 130.1 30.5 17.2" />
            <path d="m157.7 130.4-30.7 16.9" />
            <path d="m127.5 183.6-.5-36.3" />
            <path d="m35.7 129.7 30.5 17.2" />
            <path d="m96.9 130-30.7 16.9" />
            <path d="m66.7 183.2-.5-36.3" />
          </g>
        </svg>
      </Link>
      <div
        onClick={() => setNavOpen(!navOpen)}
        className={navOpen ? 'nav-button open' : 'nav-button'}
      >
        {navOpen ? (
          <svg
            className="hamburger"
            enableBackground="new 0 0 64 64"
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="32" cy="32" r="25" fill="hsl(243, 100%, 7%)" />
            <g fill="#fafafa">
              <path
                className="circle"
                d="m32 58.5c-14.6 0-26.5-11.9-26.5-26.5s11.9-26.5 26.5-26.5 26.5 11.9 26.5 26.5-11.9 26.5-26.5 26.5zm0-50c-13 0-23.5 10.5-23.5 23.5s10.5 23.5 23.5 23.5 23.5-10.5 23.5-23.5-10.5-23.5-23.5-23.5z"
              />
              <path d="m41 25.1-15.9 15.9c-.6.6-1.5.6-2.1 0s-.6-1.5 0-2.1l15.9-15.9c.6-.6 1.5-.6 2.1 0s.6 1.5 0 2.1z" />
              <path d="m38.9 41-15.9-15.9c-.6-.6-.6-1.5 0-2.1s1.5-.6 2.1 0l15.9 15.9c.6.6.6 1.5 0 2.1s-1.5.6-2.1 0z" />
            </g>
          </svg>
        ) : (
          <svg
            className="hamburger"
            enableBackground="new 0 0 64 64"
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="32" cy="32" r="25" fill="rgba(0, 0, 0, 0.3)" />
            <g fill="#fafafa">
              <path
                className="circle"
                d="m32 58.5c-14.6 0-26.5-11.9-26.5-26.5s11.9-26.5 26.5-26.5 26.5 11.9 26.5 26.5-11.9 26.5-26.5 26.5zm0-50c-13 0-23.5 10.5-23.5 23.5s10.5 23.5 23.5 23.5 23.5-10.5 23.5-23.5-10.5-23.5-23.5-23.5z"
              />
              <path d="m43.2 26h-22.4c-.9 0-1.5-.6-1.5-1.5s.6-1.5 1.5-1.5h22.5c.8 0 1.5.6 1.5 1.5s-.7 1.5-1.6 1.5z" />
              <path d="m43.2 33.5h-22.4c-.9 0-1.5-.7-1.5-1.5s.6-1.5 1.5-1.5h22.5c.8 0 1.5.6 1.5 1.5 0 .8-.7 1.5-1.6 1.5z" />
              <path d="m43.2 41h-22.4c-.9 0-1.5-.7-1.5-1.5s.6-1.5 1.5-1.5h22.5c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.6 1.5z" />
            </g>
          </svg>
        )}
        <Navigation />
      </div>
      <LanguageSwitcher
        languagePaths={languagePaths}
        forceUpdate={forceUpdate}
      />
    </header>
  );
};

export default Header;
