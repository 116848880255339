import currentLanguage from "./currentLanguage";
import {isDefaultLanguage} from "./defaultLanguage";

const urlPrefix = () => {
  const languageCode = currentLanguage();
  let urlPrefix = '/';
  if (!isDefaultLanguage(languageCode)) {
    urlPrefix += languageCode + '/';
  }
  return urlPrefix;
}

export default urlPrefix;
