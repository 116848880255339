import React from 'react';
import PropTypes from 'prop-types';
import './TitleDeck.scss';

const TitleDeck = ({ titleTag, title }) => {
  const HeaderTag = `${titleTag}`;

  return (
    <div className="title deck">
      <HeaderTag>{title}</HeaderTag>
    </div>
  );
};

TitleDeck.propTypes = {
  titleTag: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default TitleDeck;
