import {DEFAULT_LANGUAGE} from "./defaultLanguage";

const currentLanguage = () => {
  const url = window.location.pathname;

  if (url.substring(0, 3) === '/en') {
    return 'en';
  }
  else {
    return DEFAULT_LANGUAGE;
  }

};

export default currentLanguage;
