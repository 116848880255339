import React, { useRef } from 'react';
import Submit from './Submit';

const ContactFormField = ({
  fieldName,
  fields,
  formData,
  setFormdata,
  webform,
  formRef,
  resetFormFields,
  backendError,
  setBackenderror,
  TIMEOUT
}) => {
  const field = fields[`${fieldName}`];

  const thisField = useRef(null);

  const onChange = e => {
    setFormdata({
      ...formData,
      [e.target.name]: e.target.value
    });

    if (backendError[e.target.name]) {
      thisField.current.setCustomValidity('');
      setBackenderror(
        Object.keys(backendError[e.target.name]).filter(
          key => key !== e.target.name
        )
      );
    }
  };

  let InputType = field['#type'] === 'textarea' ? 'textarea' : 'input';

  const placeholder = field['#required']
    ? field['#placeholder'] + ' *'
    : field['#placeholder'];

  switch (field['#type']) {
    case 'webform_actions':
      return (
        <Submit
          fields={fields}
          field={field}
          backendError={backendError}
          setBackenderror={setBackenderror}
          resetFormFields={resetFormFields}
          webform={webform}
          formData={formData}
          setFormdata={setFormdata}
          TIMEOUT={TIMEOUT}
          formRef={formRef}
        />
      );
    default:
      return (
        <InputType
          type={field['#type']}
          name={fieldName}
          required={field['#required']}
          onChange={onChange}
          value={formData[fieldName]}
          placeholder={placeholder}
          ref={thisField}
        />
      );
  }
};

export default ContactFormField;
