import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Image from '../../base/Image';
import './ContactForm.scss';
import urlPrefix from 'util/urlPrefix';
import ContactFormField from './ContactFormField';
import TextDeck from 'components/decks/TextImage/TextDeck';

function ContactForm({ webform, title, media, textRich, ctaParagraph }) {
  const [formData, setFormdata] = useState({name: '', email: '', message: ''});
  const [fields, setFields] = useState(null);
  const [backendError, setBackenderror] = useState({});

  const formRef = useRef(null);
  const _isMounted = useRef(false);

  useEffect(() => {
    if (backendError !== {} && Object.keys(backendError).length) {
      // eslint-disable-next-line
      const elements = Object.keys(formRef.current.elements).map(key => {
        if (backendError[formRef.current.elements[key].name]) {
          return formRef.current.elements[key];
        }
      }).filter(element => element); 

      // eslint-disable-next-line
      for (let key in elements) {
        let element = elements[key];

        if (
          element.nodeName === 'INPUT' ||
          element.nodeName === 'TEXTAREA'
        ) {
          if (backendError[element.name]) {
            const parser = new DOMParser();

            // Remove HTML tags because we can't render them inside a custom validity string.
            const error = parser.parseFromString(backendError[element.name], 'text/html').body.innerText;

            element.setCustomValidity(error);
            element.reportValidity();
            break;
          }
        }
      }
    }
  }, [backendError]);

  const TIMEOUT = 4000;

  const resetFormFields = (fields, setFormdata) => {
    let emptyElements = {};

    // eslint-disable-next-line
    Object.keys(fields).map(key => {
      if (key !== 'actions') {
        emptyElements[key] = '';
      }
    });
    setFormdata(emptyElements);
  };

  useEffect(() => {
    _isMounted.current = true;

    const fetchData = async () => {
      try {
        const result = await axios(urlPrefix() + `webform_rest/${webform}/fields`);

        if (_isMounted.current) {
          setFields(result.data);
        }

        fields && resetFormFields(fields, setFormdata);
      } catch (error) {
        setFields([]);

        console.log("GET error: ", error);
      }
    };

    fetchData();

    return () => {
      _isMounted.current = false;
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className="contact-form">
      <div className="inner">
        {title && <h2 className="title">{title}</h2>}
        {media && <Image className="image" image={media} />}
        {textRich && <TextDeck text={textRich} />}
        <form className="form contact" ref={formRef}>
          {fields &&
            Object.keys(fields).map((fieldName, index) => (
              <ContactFormField
                key={index}
                fieldName={fieldName}
                fields={fields}
                formData={formData}
                setFormdata={setFormdata}
                webform={webform}
                formRef={formRef}
                backendError={backendError}
                setBackenderror={setBackenderror}
                resetFormFields={resetFormFields}
                TIMEOUT={TIMEOUT}
              />
            ))}
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
