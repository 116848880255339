import React, { useEffect } from 'react';
import currentLanguage from 'util/currentLanguage';
import ArticleItem from './ArticleItem.js';
import HeroDeck from 'components/decks/Hero/HeroDeck';

const Articles = ({ data, storeLanguagePath }) => {
  const headerTitle = currentLanguage() === 'da' ? 'Artikler' : 'Articles';
  
  const paths = {
    en: '/en/articles',
    da: '/artikler'
  };  
  
  useEffect(() => {
    storeLanguagePath(paths);

    // eslint-disable-next-line
  }, []);

  const articles = [];

  // eslint-disable-next-line 
  for (let key in data.pagebuilders) {
    let node = data.pagebuilders[key];
    if (node.isArticle) {
      articles.push(node);
    }
  }

  articles.sort((a, b) => a.created < b.created ? 1 : -1);

  return (
    articles && (
      <>
        <HeroDeck {...data.articleSettings} />
        <div className="text-image deck">
          <div className="inner">
            <h1 className="title">{headerTitle}</h1>
            {articles.map((article, index) => (
              <ArticleItem key={index} article={article} />
            ))}
          </div>
        </div>
      </>
    )
  );
};

export default Articles;