import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'vanilla-lazyload';

if (!document.lazyLoadInstance) {
  document.lazyLoadInstance = new LazyLoad();
}

const Image = ({ image }) => {
  useEffect(() => {
    document.lazyLoadInstance.update();
  }, []);

  return (
    <img
      className="image"
      data-src={image.src}
      data-srcset={image.srcset}
      data-sizes={image.sizes}
      alt={image.alt ? image.alt : "Reveal IT Drupal and React Developers"}
      onError={e => (e.target.style.display = 'none')}
    />
  );
};

Image.propTypes = {
  image: PropTypes.object.isRequired
};

export default Image;
