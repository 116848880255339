import React from 'react';
import { Link } from 'react-router-dom';
import Icon from './Icon';

import PropTypes from 'prop-types';

const Cta = ({ icon, cta: {title, uri} }) => {
  const content = (icon, title) => {
    return (
      <>
        {icon && <Icon icon={icon} />}
        <span className={`
          cta-text
          ${icon && 'with-icon'}
        `}>
          {title}
        </span>
      </>
    );
  };

  if (uri === null || uri === undefined) {
    return <span className="cta button">{content(icon, title)}</span>;
  } else if (uri.charAt(0) === '/') {
    return (
      <Link className="cta button" to={uri}>
        {content(icon, title)}
      </Link>
    );
  } else {
    return (
      <a className="cta button" href={uri} target={uri.charAt(0) !== "#" && "_blank"}>
        {content(icon, title)}
      </a>
    );
  }
};

Cta.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  uri: PropTypes.string
};

export default Cta;
